// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appVersion: '1.0.0',
  rpp: {
    apiUrl: 'https://rpp-agency-test.delfosti.site/api',
  },
};
